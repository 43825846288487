html, body {
  overflow-x: hidden;
}


body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

button {
  font-family: 'Inter', sans-serif;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-tabs__tab--selected {
  background-color: #fff;
  color: #000 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
