@import "../Common/Style.module.scss";

.contentFooterBlockMob {
  display: none;
}
.Footer {
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  //flex-wrap: wrap;
  margin-top: 50px;
}
.listColumns li {
  color: #444444;
  list-style-type: none;
  font-size: $bntFontSize;
  padding-bottom: 15px;
  display: flex;
  gap: 10px;
}
.listColumns a {
  color: #444444;
}
.mobileApp {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #444444;
  padding-bottom: 0;
}
.listColumns .titleLi,
.titleLiPay {
  color: #444444;
  font-weight: 700;
  margin-bottom: 10px;
}
.titleLiPay {
  margin-bottom: 15px;
  list-style: none;
}
.titleLiImg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
// .titleLiImg img {
//     height: 28px;
// }
.paypal {
  width: 106px;
  height: 29px;
}
.stripe {
  width: 67px;
  height: 28px;
}
.adyen {
  width: 87px;
  height: 29px;
}
.wepay {
  width: 118px;
  height: 29px;
}
.bitpay {
  width: 80px;
  height: 29px;
}
//Site Statistics
.statiscticBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  padding: 20px;
  border-radius: 50px;
  min-width: 250px;
  min-height: 310px;
  & p {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  & .statByValue {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  & .summaryUsers {
    background-color: black;
    color: white;
    padding: 2.5px;
  }
}
.statDesignVisitors {
  border: 3px solid #29407f;
  & p {
    color: #147e23;
  }
}
.statDesignUsers {
  border: 3px solid #632200;
  & p {
    color: #702e7e;
  }
}
.ptpStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #3d42ff;
  font-size: 12px;
  & img {
    width: 35px;
  }
  & a {
    //padding-right: 10px;
  }
}
.specialBlockColumn {
  display: flex;
  flex-direction: column;
}
.AllUnique {
  display: flex;
  flex-direction: row;
  .AU {
    display: flex;
    flex-direction: column;
    margin: 10px;
    text-align: center;
  }
}
.socialIcons {
  width: 35px;
  height: 35px;
}
.listUl {
  margin: 0;
  padding: 0;
}
@media all and (max-width: 800px) {
  .Footer {
    display: none;
  }
  .FooterMobile {
    display: flex;
    flex-direction: column;
  }
  /* скрываем чекбоксы и блоки с содержанием */
  .hide {
    display: none;
  }
  .hide + label ~ div {
    display: none;
  }
  /* оформляем текст label */
  .hide + label {
    padding: 0;
    font-weight: 600;
    color: #3b383b;
    cursor: pointer;
    display: inline-block;
    font-size: $bntFontSize;
    font-family: $standartFont;
  }
  /* вид текста label при активном переключателе */
  .hide:checked + label {
    color: #ff613b;
    border-bottom: 0;
    padding-bottom: 10px;
  }
  /* когда чекбокс активен показываем блоки с содержанием  */
  .hide:checked + label + div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    & img {
      height: 30px;
    }
    & a {
      color: #6c666c;
    }
  }

  .hide:checked + label + div.resortDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    & img {
      height: 30px;
    }
    & a {
      color: #6c666c;
    }
  }

  .fullWidthBlock {
    width: 100%;
  }

  .contentFooterBlockMob {
    margin-top: 20px;
    border-top: 1px solid #968e96;
    padding-top: 10px;
    width: 100%;
    min-height: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    & img {
      height: 10px;
    }
    & label {
      width: 100%;
      padding-bottom: 10px;
    }
    & li {
      list-style-type: none;
      padding-bottom: 10px;
      padding-right: 10px;
    }
  }
}
