.container {
  margin: 0 150px 0 150px;
  position: relative;
}
@media all and (max-width: 1550px){
  .container{
    margin: 0 100px 0 100px;
  }
}
@media all and (max-width: 1000px){
  .container{
    margin: 0 20px 0 20px;
  }
}
.bodyField{
  width: 100%;
}
.fullWidth{
  width: 100%;
}
.infoBottomBgImg {
  margin-top: 20px;
  padding-left: 12px;
}
@media all and (max-width: 1100px) {
  .bodyField{
    margin-top: 0;
  }
}