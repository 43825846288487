//Fonts

$standartFont: arial;


//Navbar
$navbarHeight: 50px;
$navbarFontType: URWGordonW01-Medium;
$fontItemColor: #3d3d3d;
$fontItemColorActive: #074fdc;
$navbarBtnHeight: 30px;

//Buttons
$signUpColor: #f54e2a;
$bntFontSize: 15px;

//Figure text
$figureText: "Bradley Hand";
//$figureText: arial;
$coloFigureText: #5ca44b;
$figureTextSize: 20px;

//Form style
$bgColor: #d4d4d4;
$borderColor: darkgray;
$inputBorder: 1px solid darkgray;

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.errorsBlock {
  color: #ff5264;
  padding: 5px;
  border: 1px solid #ff5264;
  margin-bottom: 10px;
  text-align: center;
}
.successBlock {
  color: #14a02f;
  padding: 5px;
  border: 1px solid #14a02f;
  margin-bottom: 10px;
  text-align: center;
}

.Container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & .colorDescriptionBlock {
    width: 70%;
    font-size: $bntFontSize;
    font-family: $standartFont;
  }
}

.MainPageContainer {
  position: relative;
  margin-top: -30px;
}
.MainPageContainer::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  width: 90%;
  height: 750px;
  background: linear-gradient(90deg, rgba(250,236,232, 0.4) 0%, rgba(250,236,232, 1) 15%, rgba(250,236,232, 0.2) 50%);
  filter: blur(30px);
  border-radius: 50px;
  z-index: -1;
}

.signUpBtn {
  background: white;
  color: $signUpColor;
  border: 3px solid $signUpColor;
  font-size: $bntFontSize;
  font-weight: 600;
  font-family: $standartFont;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: $signUpColor;
    color: white;
  }
}

.commonBtn {
  margin-top: -15px;
  border-radius: 10px;
  background: #ff7d1f;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  border: none;
  padding: 10px 0;
  color: #ffffff;
  line-height: 24px;
  font-size: 20px;
  transition: all 0.3s;
}
.commonBtn:hover {
  background-color: #FF6600;
}

.titlePageText {
  width: 90%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  margin: 15px 0 15px 0;
  padding: 0 50px;
  color: #ff8800;
  line-height: 24px;
  cursor: text;
  &.titlePageTextMargin {
    @media (max-width: 900px) {
    }
  }
}
@media (max-width: 900px) {
  .titlePageText {
    margin: 10px 0;
    line-height: 40px;
  }
}

.scrollDivText {
  max-height: 60vh;
  overflow: scroll;
  font-family: $standartFont;
}
.titleTermPrivacy {
  font-size: 18px;
  color: #147e23;
}

.seconTitleText {
  text-align: left;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 24px;
  color: #444444;
  font-size: 20px;
  cursor: text;
  @media (max-width: 1400px) {
    font-size: 18px;
  }
  @media (max-width: 1000px) {
    font-size: 16px;
  }
}
.additionalStyleLabel {
  margin-bottom: 10px;
}

.formSignUpLogInStyle {
  width: 600px;
  padding: 45px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffb764;
  border: none;
  color: #444444;
  border-radius: 20px;
  box-shadow: 
    0px 0px 0px 0px #0000001a,
    0px 19px 42px 0px #0000001a,
    0px 76px 76px 0px #00000017,
    0px 171px 103px 0px #0000000d,
    0px 304px 122px 0px #00000003,
    0px 475px 133px 0px #00000000;
    @media (max-width: 1300px) {
      box-shadow: 
    0px 0px 0px 0px #0000001a,
    0px 19px 42px 0px #0000001a,
    0px 76px 76px 0px #00000017,
    0px 171px 103px 0px #0000000d;
    }
    @media (max-width: 1200px) {
      box-shadow: 
    0px 0px 0px 0px #0000001a,
    0px 19px 42px 0px #0000001a,
    0px 76px 76px 0px #00000017;
    }
    @media (max-width: 1000px) {
      box-shadow: 
    0px 0px 0px 0px #0000001a,
    0px 19px 42px 0px #0000001a;
    }
}
.formSignUpLogInStyle select,
.formSignUpLogInStyle input,
.formSignUpLogInStyle textarea {

    border-radius: 10px;
    padding-left: 25px;
    margin-bottom: 25px;
    outline: none;
    border: none;
    color: #444444;
    font-size: 16px;
    line-height: 20px;

}

.commonSelectStyle {
  font-family: 'Inter', sans-serif;
  height: 39px;
  width: 100%;
  margin: 0;
  padding-left: 7px;
  margin: 10px 0;
  /* Removes the default <select> styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Positions background arrow image */
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: 100% - 5% center;
}

.commonInputStyle {
  min-width: 300px;
  outline: none;
  padding: 10px 10px 10px 25px;
  resize: none;
  font-family: 'Inter', sans-serif;

  &.registeredPhone {
    max-height: 20px;
    @media (max-width: 450px) {
      max-height: 40px;
    }
  }
  &.continueRegArea{
    max-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      max-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width:520px) {
    padding-left: 10px !important;
  }
  @media (max-width: 600px) {
    min-width: 100px;
    width: calc(100% - 25px);
  }

  &.mainPagePhone {
    min-width: 230px;
    @media (max-width: 1279px) {
      max-height: 100px;
      height: auto;
    }
    @media (max-width:520px) {
      padding-left: 10px !important;
    }
    @media (max-width:420px) {
      min-height: 65px;
    }
  }
}
.commonInputStyle::placeholder {
  color: #444444;
}
.InputStyleBig {
  padding: 15px 10px 10px 25px;
}
.InputStyleBig::placeholder {
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  color: #444444;
}
.formElementCenter {
  text-align: center;
}

.blockFileAdd {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
  gap: 50px;

  & .chooseFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  & .choosLabel {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: -1px;
  }

  & .commonSpanStyle {
    padding: 10px 25px 10px 25px;
  }

  & .fieldAttach {
    width: 80%;
    background: white;
    border: none;
    color: #444444;
    font-size: 16px;
    border: 1px solid #444444;
    margin-top: 10px;
    margin-bottom: 10px;
    &.mainPageField {
      border: none;
      margin: 0;
    }
  }

  & .fieldBrowse {
      background-color: #ffdeb8;
      border: none;
      outline: none;
      color: #444444;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      border-radius: 10px;
      transition: all 0.3s;
      border: 1px solid #444444;
      margin-left: 20px;
      padding: 5px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 30px;

    &.mainPageBrowse {
      width: 40%;
      max-height: 50px;
      background-color: #ffdeb8;
      border: none;
      outline: none;
      color: #444444;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      border-radius: 10px;
      transition: all 0.3s;
      padding: 10px 25px;
      height: 100%;
      @media (max-width: 450px) {
        height: 40px;
      }
    }
  }
  & .fieldBrowse:hover {
    background-color: #FFC495;
  }

}
@media (max-width: 1800px) {
  .blockFileAdd {
    gap: 0;
    flex-direction: column;
  }
  .formSignUpLogInStyle {
    max-width: 500px;
    width: 100%;
  }
}

//
.blockFileAddAt {
  background: #ffffff;
}
.twiceTwoBlocks {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.blockOfBlocks {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    margin: 10px;
    padding: 7px;
    border-radius: 5px;
    min-width: 200px;
    text-align: center;
  }
}

//

.toLinkButtonField {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 40px;
}

.ElementsBlock {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .CommonBlock {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  & .BlockImage img {
    max-width: 100px;
  }

  & .Lblock {
    display: flex;
    flex-direction: row;
    //justify-content: flex-end;
  }

  & .Rblock {
    display: flex;
    flex-direction: row-reverse;
    //justify-content: flex-end;
  }

  & .LeftSideBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  & .RightSideBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  & .titleBlockTextR {
    text-align: right;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: $bntFontSize;
  }

  & .BlockTextR {
    text-align: right;
  }

  & .titleBlockTextL {
    text-align: left;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: $bntFontSize;
  }

  & .BlockTextL {
    text-align: left;
  }
}

//For colors text
.colorBlue {
  color: #4a84ff;
}

.colorDeepBlue {
  color: #00007f;
}

.colorOrange {
  color: coral;
}

.colorPurple {
  color: #5c0a6e;
}

.colorBrown {
  color: #5e281a;
}
.colorGreenLight {
  color: #147e23;
}
.colorGray {
  color: #5e5e5e;
}
.colorPink {
  color: #d960d8;
}
.colorBiruza {
  color: #2a9481;
}

.statusonline {
  border-radius: 10px;
  padding: 3px 5px 3px 5px;
  color: white;
  background: green;
}
.statusoffline {
  border-radius: 10px;
  padding: 3px 5px 3px 5px;
  color: white;
  background: red;
}

//
.endownmentsBlock {
  min-width: 280px;
  max-width: 500px;
  padding: 10px;
  min-height: 280px;
  border: 5px double #6e3216;
  background: #f4ff69;
  color: #6e3216;
  font-size: $bntFontSize;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.justTextBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 40vh;

  & p {
    font-size: $bntFontSize;
    color: #514d51;
  }
}
.justTextBlockCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  width: 100%;
  text-align: center;

  & p {
    font-size: $bntFontSize;
    color: #514d51;
  }
}
.centerText {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.twoBlocks {
  //max-height: calc(100vh - 280px);
  //overflow: scroll;

  display: flex;
  justify-content: space-around;
  align-items: center;
  //width: 100%;
  flex-direction: row;

  & .mainFilesBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 300px;
    text-align: left;
    margin: 20px;
  }
  img {
    max-height: 50px;
    margin-right: 10px;
  }
  .downloadLinkUsTemp {
    color: green;
  }
  .downloadLinkUsTemp:hover {
    color: #064a06;
  }
}

//.bigFilesBlock{
//  display: flex;
//  justify-content: space-between;
//  flex-direction: column;
//}

.solidSignUp {
  padding-right: 20px;
  padding-left: 20px;
  background: #7a2200;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solidDBofQueries {
  padding-right: 20px;
  padding-left: 20px;
  background: #0f6362;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currentFile {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 15px;
}
.currentFiletext {
  padding-right: 10px;
}

//tooltip start

//.tooltipField{
//  width: 100%;
//  display: flex;
//  flex-direction: column;
//}
//
//.formTooltip {
//  display: block;
//  visibility: hidden;
//  overflow: hidden;
//  box-sizing: border-box;
//  margin-bottom: 0px;
//  height: 0;
//  cursor: help;
//  animation-name: fold;
//  animation-duration: 500ms;
//  animation-timing-function: ease-in;
//  animation-direction: reverse;
//  padding: 7px 7px;
//  background: #969696;
//  color: #fff;
//  font-weight: 500;
//  font-size: 14px;
//  line-height: 16px;
//}
//:focus + .formTooltip {
//  height: auto;
//  visibility: visible;
//  animation: fold ease-in 500ms;
//  animation-direction: normal;
//  margin-bottom: 10px;
//  text-align: center;
//}

//tooltip end

//loading box
.loadingBox {
  text-align: center;
}
.sorryBlock {
  text-align: center;
}
.loadMoreBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .loadMore {
    padding: 8px 25px 8px 25px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    color: white;
    background: #5ca44b;
    cursor: pointer;
    outline: none;
  }
  .loadMore:hover {
    background: white;
    color: #34a447;
  }
}
.commonInputStylePlaceholder {
  height: 20px;
}
@media (max-width: 1600px) {
  .commonInputStylePlaceholder {
    height: auto;
  }
}
@media (max-width: 1279px) {
  .commonInputStylePlaceholder {
    max-height: 20px;
  }
}
//end loading box
@media (max-width: 900px) {
  .formSignUpLogInStyle {
    width: 80%;
    margin: 10px 20px;
  }
}
@media all and (max-width: 1100px) {
  .Container {
    margin-top: 50px;
  }
}

@media all and (max-width: 520px) {
  .Container {
    & .colorDescriptionBlock {
      width: 95%;
    }
  }
  .formSignUpLogInStyle {
    min-width: 270px;
    padding: 25px;
  }
  .commonInputStyle {
    padding: 10px 10px;
  }
  .InputStyleBig {
    padding: 10px 10px;
  }
  .commonSelectStyle {
    padding-left: 10px !important;
  }
  .commonInputStylePlaceholder {
    height: auto;
    max-height: none;
  }
  .blockFileAdd {
    flex-direction: column;
    align-items: flex-start;

    & .choosLabel {
      display: flex;
      width: 100%;
    }

    & .fieldAttach {
      width: 90%;
    }

    & .fieldBrowse {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
    }
    & .commonSpanStyle {
      padding: 10px 10px;
    }
  }
  .toLinkButtonField {
    justify-content: space-between;
    flex-direction: column;
    margin: 0px;
  }
  .commonBtn {
    margin: 5px 5px 5px 0px;
    text-align: center;
  }
  .twiceTwoBlocks {
    flex-direction: column;
    .blockOfBlocks {
      width: 100%;
    }
  }
}
@media all and (max-width: 370px) {
  .fieldAttach {
    font-size: 13px;
  }
  .fieldBrowse {
    font-size: 13px;
  }
  .twoBlocks {
    & .mainFilesBlock {
      min-width: 280px;
    }
  }
}

@media all and (max-width: 800px) {
  .ElementsBlock {
    flex-wrap: wrap;

    & .CommonBlock {
      width: 100%;
    }
  }
  .twoBlocks {
    max-height: fit-content;
    overflow: auto;

    flex-direction: column;
    & .fiftyBlock {
      width: 100%;
    }
  }
}

